import { AsyncPipe } from '@angular/common';
import { Component, ViewChild, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MediaObserver } from '@angular/flex-layout';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import {
  APP_CONFIG,
  AboutComponent,
  BreadcrumbsComponent,
  DarkThemeService,
  DialogService,
  GLOBAL_STATE,
  HelpService,
  JwtService,
  MenuComponent,
  UpdaterService,
  UserProfileComponent,
} from '@davidhiu/ignite-ng';
import { RxLet } from '@rx-angular/template/let';
import { combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

/**
 * App root component.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',

  standalone: true,
  imports: [
    AsyncPipe,
    BreadcrumbsComponent,
    ExtendedModule,
    FlexModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatToolbarModule,
    MenuComponent,
    RouterLink,
    RouterOutlet,
    RxLet,
  ],
})
export class AppComponent {
  appConfig = inject(APP_CONFIG);
  private _activatedRoute = inject(ActivatedRoute);
  private _darkThemeService = inject(DarkThemeService);
  private _dialogService = inject(DialogService);
  private _domSanitizer = inject(DomSanitizer);
  private _globalState = inject(GLOBAL_STATE);
  private _helpService = inject(HelpService);
  private _jwtService = inject(JwtService);
  private _matIconRegistry = inject(MatIconRegistry);
  private _mediaObserver = inject(MediaObserver);
  private _router = inject(Router);
  private _updaterService = inject(UpdaterService);

  /**
   * The sidenav panel.
   */
  @ViewChild(MatSidenav, { static: true })
  private _sidenav!: MatSidenav;

  /**
   * User logged in flag.
   */
  loggedIn = toSignal(this._globalState.select('userLoggedIn'));

  /**
   * User profile with currently selected active workgroup and storage.
   */
  currentProfile$ = combineLatest([
    this._globalState.select('userProfile'),
    this._globalState.select('userProfilePreference'),
    this._globalState.select('userType'),
  ]).pipe(
    map(([userProfile, userProfilePreference, userType]) => {
      return {
        name: userProfile.name,
        email: userProfile.email,
        type: userType,
        roles: userProfile.roles.map((role) => role.name).join(', '),
        activeWorkgroup:
          userProfile.workgroups[userProfilePreference.activeWorkgroupIndex[this.appConfig.appId]]?.name ?? '',
        activeStorage:
          userProfile.workgroups[userProfilePreference.activeWorkgroupIndex[this.appConfig.appId]]?.storages[
            userProfilePreference.activeStorageIndex[this.appConfig.appId]
          ]?.name ?? '',
        activeWorkgroupFilters: userProfile.workgroups
          .reduce((prev, curr, index) => {
            if (
              userProfilePreference.filterWorkgroupIndexes.find(
                (filterWorkgroupIndex) => filterWorkgroupIndex === index
              ) !== undefined
            ) {
              prev.push(curr.name);
            }
            return prev;
          }, [] as string[])
          .join(', '),
      };
    })
  );

  /**
   * Current user profile to be displayed on the main toolbar.
   */
  currentProfileLabel = toSignal(
    combineLatest([
      this.currentProfile$,
      this._router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        // Start with any value so that the initial profile label is emitted. This is because
        // this observable will be subscribed later after the user is logged in, and by the
        // time, router will already has performed navigation and has emitted router event.
        startWith(() => null)
      ),
    ]).pipe(
      map(([currentProfile, _]) => {
        // Since we lazy loads dashboard component, at first emission (start with
        // null above), firstChild will be null, so use optional chaining.
        const isDashboardRoute = this._activatedRoute.snapshot.firstChild?.url[0].path.includes('dashboard');
        return (
          `${currentProfile.name} | ${currentProfile.activeWorkgroup}` +
          (isDashboardRoute ? ` | ${currentProfile.activeWorkgroupFilters}` : '')
        );
      })
    )
  );

  /**
   * Theme toggle button's label.
   */
  toggleThemeLabel = toSignal(
    this._globalState.select('darkTheme').pipe(map((darkTheme) => (darkTheme ? 'Light Theme' : 'Dark Theme')))
  );

  /**
   * The router is navigating flag.
   */
  navigating = toSignal(
    this._router.events.pipe(
      map((event) => {
        if (event instanceof NavigationStart) {
          return true;
        } else if (event instanceof NavigationEnd) {
          this._sidenav.close();
          return false;
        } else if (event instanceof NavigationCancel) {
          return false;
        } else {
          return null;
        }
      }),
      filter((mapped) => mapped !== null)
    )
  );

  /**
   * Breadcrumbs's top offset which is smaller on extra small screen.
   */
  breadcrumbsTopOffset = toSignal(
    this._mediaObserver.asObservable().pipe(map((change) => (change.find((item) => item.mqAlias === 'xs') ? 56 : 64))),
    { initialValue: 64 }
  );

  /**
   * Constructor.
   */
  constructor() {
    // Change default FontSet class from `material-icons` to `material-icons-round`.
    this._matIconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this._matIconRegistry.registerFontClassAlias('mi', 'material-icons mat-ligature-font');
    this._matIconRegistry.registerFontClassAlias('mi-rnd', 'material-icons-round mat-ligature-font');
    this._matIconRegistry.registerFontClassAlias('mi-out', 'material-icons-outlined mat-ligature-font');
    this._matIconRegistry.addSvgIcon(
      'logo',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/logo.svg')
    );

    // Initialize services.
    this._updaterService.initialize();
    this._darkThemeService.initialize();
  }

  /**
   * Open profile dialog.
   */
  openProfile(): void {
    this._dialogService.openImmediateDialog(UserProfileComponent, {
      width: this._mediaObserver.isActive('xs') || this._mediaObserver.isActive('sm') ? undefined : '50vw',
    });
  }

  /**
   * Toggle between dark and light theme.
   */
  toggleDarkTheme(): void {
    this._globalState.set('darkTheme', (state) => !state.darkTheme);
  }

  /**
   * Open help doc link.
   */
  openHelp(): void {
    this._helpService.open();
  }

  /**
   * Open about dialog.
   */
  openAbout(): void {
    this._dialogService.openImmediateDialog(AboutComponent, { autoFocus: false });
  }

  /**
   * Logout the user.
   */
  logout(): void {
    this._jwtService.logout().subscribe();
  }

  /**
   * Scroll page to top.
   */
  backToTop(): void {
    try {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }
}
